import { notebookApiService } from '@cms/services';
import { dataQuery } from './leaderboard-api';

export const notebookApi = notebookApiService(dataQuery);
export const {
  useCreateNotebookMutation,
  useGetNotebooksQuery,
  useGetFanNotebookByIdQuery,
  useGetFanNotebooksQuery,
  useDeleteNotebookMutation,
  useGetNotebookByIdQuery,
  useUpdateNotebookMutation,
} = notebookApi;
