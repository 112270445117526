import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  email: string;
  id: string;
  avatar?: string;
  phone?: string;
  creator_id?: string;
  name: string;
  username: string;
  isOnboarded: boolean;
}

export interface AppState {
  user: IUser | null;
}

const initialState: AppState = {
  user: {
    email: '',
    id: '',
    name: '',
    username: '',
    creator_id: '',
    isOnboarded: false,
  },
};

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = {
        ...state.user,
        ...action.payload,
        creator_id: state.user?.creator_id || '',
      };
    },
    setCreatorId: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.creator_id = action.payload;
      }
    },
    clearUser: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setUser, clearUser, setCreatorId } = counterSlice.actions;

export const userReducer = counterSlice.reducer;
